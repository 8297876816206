<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-07-06 11:08:09
 * @watermark: 
 * @LastEditors: wq
-->

<template>
  <div class="plan-nodes">
    <div class="title">
      任务航点
    </div>
    <ul class="node-list">
      <li
        :class="['node-li', node.real ? 'hasBackground' : '']"
        v-for="(node, index) in nodes"
        :key="node.id"
      >
        <div :class="['node-number', node.real ? 'hasIconBackground' : '']">
          {{ pad(index + 1, 3) }}
        </div>
        <img
          class="node-icon"
          :src="require(`../../assets/img/map/` + node.icon)"
        />
        <div class="node-label">
          {{ node.label }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NodeList",
  props: {
    nodes: Array
  },
  data() {
    return {};
  },
  methods: {
    pad(num, n) {
      var len = num.toString().length;
      while (len < n) {
        num = "0" + num;
        len++;
      }
      return num;
    }
  }
};
</script>

<style lang="scss" scoped>
.plan-nodes {
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.node-list {
  list-style: none;
  margin: 0px;
  margin-top: 15px;
  color: #fff;
  box-sizing: border-box;
  padding: 0px;
  overflow: scroll;
  height: calc(100% - 57px - 15px);
  font-size: 16px;
}
.title {
  color: #7dfbfd;
  font-size: 12px;
  font-weight: bold;
  height: 57px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 21px;
  position: relative;
  border-bottom: solid 1px rgba(61, 251, 253, 0.4);
}
.title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -3px;
  left: 20px;
  border-radius: 5px;
  width: 5px;
  height: 5px;
  background: rgba(61, 251, 253, 1);
}
.title::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  right: 20px;
  width: 48px;
  height: 3px;
  border-radius: 3px;
  background: rgba(61, 251, 253, 1);
}
.node-li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  //border-bottom: solid 1px #ffffffa1;
}
.node-li:hover {
  background: rgba(61, 251, 253, 0.7);
}
.node-li::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0px;
  left: 4%;
  width: 92%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}
.node-number {
  display: flex;
  height: 18px;
  width: 32px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background: #fff;
  color: #000;
}
.node-icon {
  width: 22px;
  height: 28px;
  margin: 0px 10px;
}
.node-label {
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hasBackground {
  background: rgba(0, 0, 0, 0.7);
}
.hasIconBackground {
  background: rgba(39, 144, 141, 1);
  color: #fff;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #fff;
}
</style>
