var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pitching" }, [
    _c(
      "ul",
      { attrs: { id: "pitchingBox" } },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "li",
          { key: index, class: item % 10 === 0 ? "bold" : "normal" },
          [
            item % 10 === 0
              ? _c("span", { staticClass: "scale" }, [_vm._v(_vm._s(item))])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _c("i", { staticClass: "mark" }, [
      _vm._v(_vm._s(_vm.fix(_vm.pitching, 2))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }