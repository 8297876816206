<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-07-06 14:54:07
 * @watermark: 
 * @LastEditors: wq
-->
<template>
  <div
    v-if="mapPopup"
    @mousedown="drag($event)"
    :class="['fly-popup', slide ? 'slide-up' : '']"
    :style="{ top: timePosition.top + 'px', left: timePosition.left + 'px' }"
  >
    <ul class="map-popup-ul">
      <li class="map-popup-li li-plan-title">
        {{ popupTitle }}
      </li>
      <li class="map-popup-li" v-for="item in popupLabels" :key="item.name">
        <span class="li-title">{{ item.name }}</span>
        <span class="map-popup-li-split">:</span>
        <span class="map-popup-li-value">{{ item.value }}{{ item.unit }}</span>
      </li>
    </ul>
    <div
      :class="slide ? 'slide-down-btn' : 'slide-up-btn'"
      @click="triggerSlide($event)"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { string2position } from "@/util/mapUtils";

export default {
  name: "FlyPopup",
  data() {
    return {
      timePosition: {
        top: 0,
        left: 0,
      },
      removeHandler: null,
      customize: false,
      slide: false,
      offset: {
        x: 0,
        y: 0,
      },
    };
  },
  mounted() {
  },
  props: {
    popupLabels: Object,
    cards: Array,
    popupTitle: String,
    noFixed: Array,
  },
  watch: {
    mapPopup(newVal, oldVal) {
      //已有移除方法说明已经注册过球体拖拽事件
      if (this.removeHandler) {
        return;
      }
      let self = this;
      //弹窗位置与球体拖拽事件关联
      this.removeHandler = this.map3d.clock.onTick.addEventListener(function (
        clock
      ) {
        if (self.draging) {
          return;
        }
        self.updatePosition(self.mapPopup);
        self.updatePopupLine(self.mapPopup);
      });
    },
    cards: {
      deep: true,
      handler: function (newVal) {
        this.updateCard(newVal);
      },
    },
  },
  methods: {
    drag(e) {
      this.customize = true;
      this.draging = true;
      let odiv = e.currentTarget; //获取目标元素
      //算出鼠标相对元素的位置
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      const startX = e.clientX;
      const startY = e.clientY;
      let self = this;
      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        //绑定元素位置到positionX和positionY上面
        self.timePosition.top = top;
        self.timePosition.left = left;
        //var cartesian = self.map3d.scene.globe.pick(self.map3d.camera.getPickRay({x: self.timePosition.left, y: self.timePosition.top}),self.map3d.scene);
        self.updatePopupLine(self.timePosition);
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
        self.draging = false;
        self.offset.x = self.offset.x + (e.clientX - startX);
        self.offset.y = self.offset.y + (e.clientY - startY);
      };
    },
    /**
     * @description: 拖拽地球时更新弹窗位置
     * @param {*} position
     * @return {*}
     * @author: wq
     */
    clearPopup() {
      this.popupLabels.map((x) => {
        x.value = 0;
      });
    },
    updatePosition(position) {
      const canvasPosition =
        this.map3d.scene.cartesianToCanvasCoordinates(position);
      if (canvasPosition) {
        this.timePosition.top = canvasPosition.y + this.offset.y; // - 100
        this.timePosition.left = canvasPosition.x + this.offset.x; // + 60
        // this.timePosition.top = canvasPosition.y + 20;
        // this.timePosition.left = canvasPosition.x - 250/2;
      }
    },
    triggerSlide(ev) {
      this.slide = !this.slide;
      // this.$store.commit("UPDATE_POPUP_LINE", {value: this.slide?190:380, type: 'popupHeight'});
    },
    clearHandler() {
      //已有移除方法说明已经注册过球体拖拽事件
      if (this.removeHandler) {
        this.removeHandler();
        this.removeHandler = null;
      }
    },
    updatePopupLine(position) {
      let pop = Cesium.clone(position);
      if (!pop.left) {
        const canvasPosition =
          this.map3d.scene.cartesianToCanvasCoordinates(pop);
        if (canvasPosition) {
          pop.top = canvasPosition.y + this.offset.y; // - 100
          pop.left = canvasPosition.x + this.offset.x; // + 60
        }
      }
      this.$store.commit("UPDATE_POPUP_LINE", {
        value: pop,
        type: "start",
      });
    },

    updateCard(cardInfo) {
      cardInfo.forEach((card) => {
        const label = this.popupLabels.find((x) => {
          return x.packAlias === card.packageAlines;
        });
        if (label) {
          var n = Number(card.value);
          if (!isNaN(n)) {
            const index = this.noFixed.findIndex((x) => x == label.key);
            if (index > -1) {
              if (label.key == "lon" || label.key == "lat") {
                n = string2position(card.value);
              }
              label.value = n;
            } else {
              if (label.key == "relative_alt" || label.key == "alt") {
                n = n / 1000;
              }
              let s = n % 1;
              const dd = s == 0 ? 0 : 2;
              label.value = n.toFixed(dd);
            }
          } else {
            label.value = card.value;
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters(["map3d", "mapPopup"]),
  },
  beforeDestroy() {
    this.clearHandler();
  },
};
</script>

<style lang="scss" scoped>
.fly-popup {
  position: absolute;
  width: 250px;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 14px;
  overflow: hidden;
  color: #7dfbfd;
  display: flex;
  flex-direction: column;
  border: 1px solid #ffff00;
}
.map-popup-ul {
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 8;
  overflow: auto;
  widows: 100%;
  font-size: 14px;
  margin-bottom: 20px;
}
.map-popup-li {
  margin-bottom: 5px;
  display: flex;
}
.li-title {
  flex: 4;
  text-align: right;
}
.li-plan-title {
  justify-content: center;
}
.map-popup-li-split {
  margin: 0 5px;
}
.map-popup-li-value {
  font-weight: bold;
  flex: 3;
  text-align: left;
}
.slide-up {
  height: 220px;
}
.slide-up-btn {
  flex: 1;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 11px solid #fff;
  margin: 0 auto;
}
.slide-down-btn {
  flex: 1;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 11px solid #fff;
  margin: 0 auto;
}
</style>
