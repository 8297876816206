<template>
  <div class="elevation">
    <ul id="elevationBox">
      <li
        v-for="(item) in list"
        :key="item"
        :class="Number(item) % 5 === 0 ? 'big' : ''"
      >
        <span class="scale" v-if="Number(item) % 5 === 0">{{ item }}</span>
      </li>
    </ul>
    <i class="mark">{{ fix(height, 1) }}</i>
    <!-- <i class="standard">{{ fix(standard, 1) }}</i> -->
  </div>
</template>

<script>
import { toPrecision } from "@/util/util";
import BigNumber from "bignumber.js";
export default {
  mounted() {
    this.init(this.max);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  // computed: {
  //   relative_alt() {
  //     return this.$store.state.screenLeftData.relative_alt;
  //   },
  // },
  props: {
    relative_alt: Number,
  },
  watch: {
    relative_alt: function (val) {
      if (val) {
        val = parseInt(val);
        //当前值大于max-100?max+1000
        const abs = new BigNumber(val).abs().toNumber();
        if (abs >= this.max - 100 || abs <= this.max - 1000) {
          //动态增加刻度盘
          this.max = abs + 100;
          this.init(this.max);
        }
        this.height = val;
        this.makeScroll();
      }
    },
  },
  data() {
    return {
      list: [],
      height: 0, //当前速度
      compare: 0, //暂存
      top: 0,
      standard: 0,
      max: 500,
    };
  },
  methods: {
    init(val) {
      const list = [];
      for (let i = val; i >= -val; i--) {
        list.push(i);
      }
      this.list = list;
    },
    fix(val, precision) {
      if (!val) return "0";
      return toPrecision(val, precision);
    },
    makeScroll() {
      const box = document.querySelector("#elevationBox");
      const difference = Number(this.height - this.compare);
      this.top = this.top + difference;
      box.style.top = `calc(50% + ${this.top * 12}px)`;
      this.compare = this.height;
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #4dfcff;
ul {
  margin: 0;
  padding: 0;
}
.elevation {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;
  .mark {
    // width: 52px;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    background: $color;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 2px;
    text-align: left;
    padding: 0 10px;
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid $color;
      position: absolute;
      left: -5px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  .standard {
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    background: #99999950;
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    left: 40px;
    top: 40%;
    transform: translate(0, -50%);
    border-radius: 2px;
    text-align: left;
    padding: 0 10px;
  }
  ul {
    transition: linear 0.1s;
    position: absolute;
    border-left: 2px solid $color;
    position: absolute;
    left: 20px;
    top: calc(50%);
    transform: translate(0, -50%);
    li {
      width: 10px;
      height: 2px;
      background: $color;
      margin: 10px 0;
      position: relative;
      .scale {
        font-size: 16px;
        position: absolute;
        top: -12px;
        left: 24px;
        white-space: nowrap !important;
      }
    }
    .big {
      width: 20px;
    }
  }
}
</style>
