<template>
  <div class="pitching">
    <ul id="pitchingBox">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="item % 10 === 0 ? 'bold' : 'normal'"
      >
        <span class="scale" v-if="item % 10 === 0">{{ item }}</span>
      </li>
    </ul>
    <i class="mark">{{ fix(pitching, 2) }}</i>
  </div>
</template>

<script>
import { toPrecision } from "@/util/util";
export default {
  mounted() {
    for (let i = 180; i >= -180; i -= 5) {
      this.list.push(i);
    }
    // this.timer = setInterval(() => {
    //   if (this.pitching > 80) {
    //     this.pitching = 0;
    //   } else {
    //     this.pitching += 1;
    //   }
    //   this.makeScroll();
    // }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  props: {
    pitch: Number,
  },
  // computed: {
  //   pitch() {
  //     return this.$store.state.screenLeftData.pitch;
  //   },
  // },
  watch: {
    pitch: function (val) {
      if (val) {
        this.pitching = val;
        this.makeScroll();
      }
    },
  },
  data() {
    return {
      list: [],
      pitching: 0, //当前速度
      compare: 0, //暂存
      top: 0,
    };
  },
  methods: {
    fix(val, precision) {
      if (!val) return "0";
      return toPrecision(val, precision);
    },
    makeScroll() {
      //1=>12
      const box = document.querySelector("#pitchingBox");
      const difference = Number(this.pitching - this.compare);
      this.top = this.top + difference;
      box.style.top = `calc(50% + ${this.top * (18 / 5)}px)`;
      this.compare = this.pitching;
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #4dfcff;
ul {
  margin: 0;
  padding: 0;
}
.pitching {
  width: 260px;
  height: 120px;
  overflow: hidden;
  position: relative;
  .mark {
    height: 24px;
    padding: 0 8px;
    line-height: 24px;
    background: $color;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 2px;
    text-align: left;
    padding: 0 10px;
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid $color;
      position: absolute;
      left: -5px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  ul {
    transition: linear 0.1s;
    position: absolute;
    left: 50%;
    top: calc(50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    li {
      width: 10px;
      height: 2px;
      background: $color;
      margin: 8px 0;
      position: relative;
      .scale {
        font-size: 16px;
        position: absolute;
        top: -12px;
        right: -28px;
        white-space: nowrap !important;
      }
    }
    .bold {
      width: 80px;
      height: 2px;
    }
    .normal {
      width: 60px;
      height: 2px;
      background: #79cde5;
    }
  }
}
</style>
