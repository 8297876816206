var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "slider",
      staticClass: "slider",
      on: {
        click: function ($event) {
          return _vm.clickProcess($event)
        },
      },
    },
    [
      _c("div", { staticClass: "process", style: { width: _vm.width } }),
      _c("div", { staticClass: "buffer", style: { width: _vm.bufferWidth } }),
      _c(
        "div",
        { ref: "trunk", staticClass: "thunk", style: { left: _vm.left } },
        [_c("div", { staticClass: "block" })]
      ),
      _c("div", { ref: "timeLabel", staticClass: "opacity" }, [
        _vm._v("\n    " + _vm._s(_vm.timeLabel) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }