<template>
  <div class="speed">
    <ul id="speedBox">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="index % 5 === 0 ? 'big' : 'none'"
      >
        <span class="scale" v-if="index % 5 === 0">{{ item }}</span>
      </li>
    </ul>
    <i class="mark">{{ fix(speed, 1) }}</i>
    <!-- <i class="standard">{{ fix(standard, 1) }}</i> -->
  </div>
</template>

<script>
import { toPrecision } from "@/util/util";
export default {
  mounted() {
    for (let i = 600; i >= -600; i--) {
      this.list.push(i);
    }
    // this.timer = setInterval(() => {
    //   if (this.speed > 80) {
    //     this.speed = 0;
    //   } else {
    //     this.speed += 2;
    //   }
    //   this.makeScroll();
    // }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  // computed: {
  //   uav_airespeed() {
  //     return this.$store.state.screenLeftData.uav_airespeed;
  //   },
  // },
  props: {
    uav_airespeed: Number,
  },
  watch: {
    uav_airespeed: function (val) {
      if (val) {
        this.speed = val;
        this.makeScroll();
      }
    },
  },
  data() {
    return {
      list: [],
      speed: 0, //当前速度
      compare: 0, //暂存
      top: 0,
      standard: 0,
    };
  },
  methods: {
    fix(val, precision) {
      if (!val) return "0";
      return toPrecision(val, precision);
    },
    makeScroll() {
      //1=>12
      const box = document.querySelector("#speedBox");
      const difference = Number(this.speed - this.compare);
      this.top = this.top + difference;
      box.style.top = `calc(50% + ${this.top * 12}px)`;
      this.compare = this.speed;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
}
$color: #4dfcff;
.speed {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;
  .mark {
    // width: 52px;
    padding: 0 8px;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    background: $color;
    color: #000;
    font-weight: bold;
    position: absolute;
    right: 38px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 2px;
    text-align: right;
    padding: 0 10px;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid $color;
      position: absolute;
      right: -5px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
  .standard {
    // width: 52px;
    padding: 0 8px;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    background: #99999950;
    color: rgb(0, 0, 0);
    font-weight: bold;
    position: absolute;
    right: 38px;
    top: 40%;
    transform: translate(0, -50%);
    border-radius: 2px;
    text-align: right;
    padding: 0 10px;
  }
  ul {
    transition: linear 0.1s;
    position: absolute;
    border-right: 2px solid $color;
    position: absolute;
    right: 20px;
    top: calc(50%);
    transform: translate(0, -50%);
    li {
      width: 10px;
      height: 2px;
      background: $color;
      margin: 10px 0;
      position: relative;
      .scale {
        font-size: 16px;
        position: absolute;
        top: -12px;
        right: 24px;
        white-space: nowrap !important;
      }
    }
    .big {
      width: 10px;
    }
    .none {
      width: 0;
    }
  }
}
</style>
