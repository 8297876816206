var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mapPopup
    ? _c(
        "div",
        {
          class: ["fly-popup", _vm.slide ? "slide-up" : ""],
          style: {
            top: _vm.timePosition.top + "px",
            left: _vm.timePosition.left + "px",
          },
          on: {
            mousedown: function ($event) {
              return _vm.drag($event)
            },
          },
        },
        [
          _c(
            "ul",
            { staticClass: "map-popup-ul" },
            [
              _c("li", { staticClass: "map-popup-li li-plan-title" }, [
                _vm._v("\n      " + _vm._s(_vm.popupTitle) + "\n    "),
              ]),
              _vm._l(_vm.popupLabels, function (item) {
                return _c(
                  "li",
                  { key: item.name, staticClass: "map-popup-li" },
                  [
                    _c("span", { staticClass: "li-title" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("span", { staticClass: "map-popup-li-split" }, [
                      _vm._v(":"),
                    ]),
                    _c("span", { staticClass: "map-popup-li-value" }, [
                      _vm._v(_vm._s(item.value) + _vm._s(item.unit)),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
          _c("div", {
            class: _vm.slide ? "slide-down-btn" : "slide-up-btn",
            on: {
              click: function ($event) {
                return _vm.triggerSlide($event)
              },
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }