var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cesium-div" },
    [
      _c(
        "div",
        { attrs: { id: "cesiumContainer_newMap" } },
        [
          _c("canvas", {
            staticClass: "line-div",
            attrs: { id: "map-linecanvas", title: _vm.realLineData },
          }),
          _c("fly-popup", {
            ref: "uva",
            staticClass: "flypopup",
            attrs: {
              popupData: _vm.popup,
              popupLabels: _vm.popupLabels,
              cards: _vm.cards,
              popupTitle: _vm.popupTitle,
              noFixed: _vm.noFixed,
              per: _vm.percent.value,
            },
          }),
        ],
        1
      ),
      _vm.taskStatus === 4 && _vm.ready
        ? _c(
            "div",
            { staticClass: "player-controller" },
            [
              !_vm.trigger
                ? _c("img", {
                    staticClass: "play-btn",
                    attrs: { src: require("../../assets/img/map/start.png") },
                    on: { click: _vm.triggerAnimation },
                  })
                : _c("img", {
                    staticClass: "play-btn",
                    attrs: { src: require("../../assets/img/map/stop.png") },
                    on: { click: _vm.triggerAnimation },
                  }),
              _c("Percent", {
                attrs: {
                  timeLabel: _vm.timeLabel,
                  max: _vm.percent.max,
                  min: _vm.percent.min,
                  value: _vm.percent.value,
                  bufferPercent: _vm.bufferPercent,
                },
                on: { percentChange: _vm.percentChange },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "video-plane-box",
          class: !_vm.showVideoHandle ? "hide-plane-box" : "",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showVideoHandle,
                  expression: "showVideoHandle",
                },
              ],
              staticStyle: { position: "relative" },
            },
            [
              _c("span", { staticClass: "video-plane-title" }, [
                _vm._v(_vm._s(_vm.$t("UAVFootage"))),
              ]),
              _c("i", {
                staticClass: "el-icon-close close-handle",
                on: { click: _vm.videoHandleClose },
              }),
            ]
          ),
          _vm.dialogData && _vm.liveVideoVOList.length > 0
            ? _c(_vm.useLive, {
                ref: "videoLive",
                tag: "component",
                staticClass: "video-plane",
                attrs: {
                  dialogData: _vm.dialogData,
                  lonArr: _vm.lonArr,
                  showclose: false,
                  liveVideoVOList: _vm.liveVideoVOList,
                },
                on: { showLive: _vm.videoHandleOpen },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("node-list", {
        class: ["nodes", _vm.taskStatus === 3 ? "" : "full-height"],
        attrs: { nodes: _vm.nodes },
      }),
      _c(
        "div",
        {
          staticClass: "route-line",
          on: {
            click: function ($event) {
              return _vm.updateRoute(_vm.$options.map3d, _vm.taskId)
            },
          },
        },
        [
          _c("i", { staticClass: "el-icon-refresh-left" }),
          _c("span", { staticStyle: { "margin-left": "2px" } }, [
            _vm._v(_vm._s(_vm.$t("refresh"))),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "route-task-line" },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.showHiddenRoute },
              model: {
                value: _vm.isShowRoute,
                callback: function ($$v) {
                  _vm.isShowRoute = $$v
                },
                expression: "isShowRoute",
              },
            },
            [
              _c("span", { staticClass: "Violet-line" }),
              _vm._v(_vm._s(_vm.$t("mission.missionRoute"))),
            ]
          ),
        ],
        1
      ),
      _vm.hoverTip.show
        ? _c(
            "div",
            {
              staticClass: "hover-tip",
              style:
                "\n      left: " +
                _vm.hoverTip.left +
                "px;\n      top: " +
                _vm.hoverTip.top +
                "px;\n      transform: translateY(calc(-100% - " +
                (_vm.hoverTip.type === _vm.NodeMap.Node.POINT ? 40 : 18) +
                "px)) translateX(-50%);\n    ",
            },
            [
              _c("div", { staticClass: "hover-tip-triangle" }),
              _vm._l(_vm.hoverTip.list, function (item) {
                return _c(
                  "div",
                  { key: item.label, staticClass: "hover-tip-item" },
                  [
                    _c("span", [_vm._v(_vm._s(item.label) + ": ")]),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c("BaseStationPop", {
        ref: "BaseStationPop",
        attrs: { id: "newMap", detailData: _vm.baseStationDetail },
      }),
      _vm.hangarShow
        ? _c("HangarDetails", {
            ref: "hangarDetails",
            attrs: {
              show: _vm.hangarShow,
              baseData: _vm.hangarBaseData,
              detailsData: _vm.hangarDetailsData,
              videoData: _vm.hangarVideoData,
            },
            on: {
              "update:show": function ($event) {
                _vm.hangarShow = $event
              },
              closeDetails: _vm.closeHangarPanel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }