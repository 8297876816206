<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-11-16 10:27:54
 * @watermark: 
 * @LastEditors: wq
-->
<template>
  <div class="hudContainer">
    <div class="orientationWrap">
      <Orientation :heading="store.heading" />
    </div>
    <div class="board">
      <div class="speedWrap">
        <Speed :uav_airespeed="store.uav_airespeed" />
      </div>
      <div class="dialBordWrap">
        <DialBord :roll="store.roll" :pitch="store.pitch" />
      </div>
      <div class="elevationWrap">
        <Elevation :relative_alt="store.relative_alt" />
      </div>
    </div>
    <div class="bottom">
      <Bottom :store="store" />
    </div>
  </div>
</template>

<script>
import DialBord from "./dialBord.vue";
import Pitching from "./pitching.vue";
import Speed from "./speed.vue";
import Orientation from "./orientation.vue";
import Elevation from "./elevation.vue";
import Bottom from "./bottom.vue";
export default {
  components: { DialBord, Pitching, Speed, Elevation, Orientation, Bottom },
  props: {
    store: {
      type: Object,
      default: {
        uav_airespeed: 0, // 表空速
        uav_groundspeed: 0, //地速
        alt: 0, //海拔高度
        ranagefinder_distance: 0, //激光测距高度
        pitch: 0, //俯仰
        roll: 0, //滚转
        relative_alt: 0, //相对高度
        heading: 0,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
}
.hudContainer {
  color: white;
  * {
    user-select: none;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px !important;
  height: 360px !important;
  margin: 0 auto;
  background-image: url("~@/assets/img/map/hud-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .orientationWrap {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .board {
    display: flex;
    height: 320px;
    .speedWrap {
      height: 100%;
      width: 120px;
    }
    .dialBordWrap {
      height: 100%;
      width: calc(100% - 250px);
      display: flex;
      justify-content: center;
      position: relative;
      align-items: center;
    }
    .elevationWrap {
      height: 100%;
      width: 130px;
    }
  }
  .bottom {
    height: 80px;
    width: 100%;
  }
}
</style>
