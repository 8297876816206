<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-11-16 10:27:54
 * @watermark: 
 * @LastEditors: wq
-->
<template>
  <div class="bottom">
    <div class="flex left">
      <div>
        <p>EAS：</p>
        <span>{{ fix(store.uav_airespeed, 1) }}</span>
      </div>
      <div>
        <p>GS：</p>
        <span>{{ fix(store.uav_groundspeed, 1) }}</span>
      </div>
    </div>
    <div class="flex right">
      <div>
        <p>AMSL：</p>
        <span>{{ fix(store.alt, 0) }}</span>
      </div>
      <div>
        <p>RFH：</p>
        <span>{{ fix(store.ranagefinder_distance, 1) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { toPrecision } from "@/util/util";
export default {
  data() {
    return {};
  },
  props:{
    store: Object,
  },
  // computed: {
  //   store() {
  //     return this.$store.state.screenLeftData;
  //   },
  // },
  methods: {
    fix(val, precision) {
      if (!val) return "0";
      return toPrecision(val, precision);
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #4dfcff;
.bottom {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  // padding: 10px 50px;
  p {
    font-size: 16px;
    font-weight: bold;
    text-align: right;
  }
  span {
    font-size: 16px;
    color: $color;
    font-weight: bold;
  }
  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  .left {
    p {
      width: 100px;
    }
  }
  .right {
    p {
      width: 80px;
    }
    span {
      width: 100px;
      text-align: left;
    }
  }
}
</style>
