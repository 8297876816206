<!--
 * @Description: 
 * @Autor: wq
 * @Date: 2021-06-25 16:26:36
 * @watermark: 
 * @LastEditors: wq
-->
<template>
  <div class="cesium-div">
    <div id="cesiumContainer_newMap">
      <canvas id="map-linecanvas" class="line-div" :title="realLineData"></canvas>
      <fly-popup
        class="flypopup"
        :popupData="popup"
        :popupLabels="popupLabels"
        :cards="cards"
        :popupTitle="popupTitle"
        ref="uva"
        :noFixed="noFixed"
        :per="percent.value"
      ></fly-popup>
    </div>
    <div class="player-controller" v-if="taskStatus === 4 && ready">
      <img
        @click="triggerAnimation"
        v-if="!trigger"
        class="play-btn"
        src="../../assets/img/map/start.png"
      />
      <img
        @click="triggerAnimation"
        v-else
        class="play-btn"
        src="../../assets/img/map/stop.png"
      />
      <Percent
        @percentChange="percentChange"
        :timeLabel="timeLabel"
        :max="percent.max"
        :min="percent.min"
        :value="percent.value"
        :bufferPercent="bufferPercent"
      ></Percent>
    </div>
    <!-- <HUD class="hud-plane" :store="hud"></HUD> -->
    <div class="video-plane-box" :class="!showVideoHandle? 'hide-plane-box':''">
      <div v-show="showVideoHandle" style="position: relative;">
        <span  class="video-plane-title">{{$t('UAVFootage')}}</span>
        <i class="el-icon-close close-handle" @click="videoHandleClose"></i>
      </div>
      <component
        v-if="dialogData && liveVideoVOList.length > 0"
        :dialogData="dialogData"
        :is="useLive"
        ref="videoLive"
        class="video-plane"
        :lonArr="lonArr"
        :showclose="false"
        @showLive="videoHandleOpen"
        :liveVideoVOList="liveVideoVOList"
      >
      </component>
    </div>
    <node-list
      :class="['nodes', taskStatus === 3 ? '' : 'full-height']"
      :nodes="nodes"
    >
    </node-list>
    <div @click="updateRoute($options.map3d, taskId)" class="route-line">
      <i class="el-icon-refresh-left"></i>
      <span style="margin-left:2px">{{$t('refresh')}}</span>
    </div>
    <div
      class="route-task-line">
       <el-checkbox
        @change="showHiddenRoute"
        v-model="isShowRoute"><span class="Violet-line"></span>{{$t('mission.missionRoute')}}</el-checkbox>
    </div>
    <div 
      v-if="hoverTip.show"
      :style="`
        left: ${hoverTip.left}px;
        top: ${hoverTip.top}px;
        transform: translateY(calc(-100% - ${hoverTip.type===NodeMap.Node.POINT? 40: 18}px)) translateX(-50%);
      `"
      class="hover-tip">
      <div class="hover-tip-triangle"></div>
      <div class="hover-tip-item" v-for="item in hoverTip.list" :key="item.label">
        <span>{{ item.label }}: </span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <!-- 基站悬浮窗 -->
    <BaseStationPop
      ref="BaseStationPop"
      :id="'newMap'"
      :detailData="baseStationDetail"
    />
    <!-- 机库详情弹窗 -->
    <HangarDetails
      v-if="hangarShow"
      ref="hangarDetails"
      :show.sync="hangarShow"
      :baseData="hangarBaseData" 
      :detailsData="hangarDetailsData"
      :videoData="hangarVideoData"
      @closeDetails="closeHangarPanel"
    />
  </div>
</template>

<script>
import Map3D from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import NodeList from "./node-list";
import FlyPopup from "./fly-popup";
import HUD from "../hud/index.vue";
import VdieoLiveEasyplayer from "../video-live-easyPlayer/index.vue";
import VdieoLiveHWLLS from "../video-live-HWLLS/index.vue";
import Percent from "./percent.vue";
import takeOffIcon from "../../assets/img/map/take-off_line_44x44.png";
import landLineIcon from "../../assets/img/map/landing_line_red_44x44.png";
import XCIcon from "@/assets/img/map/XC_64x64_.png";
import store from "@/store";
import { mapGetters } from "vuex";
import {
  string2position,
  segmentsIntr,
  lengthOfLine,
} from "@/util/mapUtils";
import {
  getDict,
  findHistoryRouteTrack
} from "@/api/map/index";
import { getInspectionObjects } from "@/api/project/label";
// 林斑shp数据
import greenLandProvider from '@/components/cesium/mixins/greenLandProvider.vue';
// 航线数据加载
import routeLine from '@/components/cesium/mixins/routeLine.vue';
// 加载巡检数据
import oilInspectionVue from '@/components/cesium/mixins/oilInspection.vue';
import noFlyZoneShowVue from "@/components/cesium/mixins/noFlyZoneShow.vue";
import airportShowVue from "@/components/cesium/mixins/airportShow.vue";
import basestationShowVue from "@/components/cesium/mixins/basestationShow.vue";
import hangarVue from "@/components/cesium/mixins/hangar.vue";
import hangarShowVue from "@/components/cesium/mixins/hangarShow.vue";
import NodeMap from '@/components/cesium/js/node/index';
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';
// 无人机图标类型
import { findEquipmentModel } from "@/api/map/index"

// 绑定和解绑事件
import {
  addEvent,
  removeEvent
} from "@/util/util";

export default {
  name: "Map",
  mixins: [
    greenLandProvider,
    routeLine,
    oilInspectionVue,
    noFlyZoneShowVue, 
    airportShowVue,
    basestationShowVue,
    hangarVue,
    hangarShowVue
  ],
  data() {
    return {
      NodeMap,
      viewModel: Cesium.SceneMode.SCENE3D,
      trigger: false,
      coordsKey: [
        "waypoint",
        "loiter",
        "circlingChangeHeight",
        "hoverTakeOff",
        "hoverLanding",
      ],
      backPoint: null,
      nodes: [],
      popup: [],
      popupLabels: [],
      cards: [],
      popupTitle: null,
      ready: true,
      hud: {
        uav_airespeed: 0, // 表空速
        uav_groundspeed: 0, //地速
        alt: 0, //海拔高度
        ranagefinder_distance: 0, //地速
        pitch: 0, //俯仰
        roll: 0, //滚转
        relative_alt: 0, //相对高度
        heading: 0, //偏转角
      },
      noFixed: ["uav_heading", "lon", "lat", "target_waypoint_current"],
      percent: {
        total: 0,
        passTime: 0,
        max: 100,
        min: 0,
        value: 0,
        currentIndex: 0,
        handler: null,
        timeInterval: 0,
        totalPoint: 0,
      },
      lonArr: [],
      flyDataBuffer: [],
      flyDataPages: [],
      bufferPercent: 0,
      showVideoHandle: true,
      screening: false,
      // 无人机图标
      XCIcon
    }
  },
  props: {
    taskAreaFileUrl: {
      type: String,
    },
    taskRouteUrl: {
      type: String,
    },
    taskStatus: {
      type: String,
    },
    taskId: {
      type: String,
    },
    tlfp: {
      type: String,
    },
    dialogData: {
      type: Object,
    },
  },
  components: {
    NodeList,
    FlyPopup,
    HUD,
    Percent,
    VdieoLiveEasyplayer,
    VdieoLiveHWLLS
  },
  mounted() {
    this.$options.flyRouteHook = liveFlyRouteHook();
    findEquipmentModel({
      sn: this.dialogData.airPlaneSn
    }).then(res=>{
      if (res.data.code === 200) {
        const data = res.data.data
        this.XCIcon = data ? data.modelIcon : this.XCIcon
      }
    }).finally(()=>{
      this.$options.map3d = new Map3D("cesiumContainer_newMap", {
        sceneMode: this.viewModel,
        i18n: this.$i18n
      });
      this.$store.commit("SET_MAP", this.$options.map3d);
      this.$options.flyRouteHook.setMap({map3d: this.$options.map3d, flylineLayerID: "flylineLayer", 
        showVisualPolygon: true});
      this.initNoflyShowLayer();
      this.initAirportShowLayer();
      this.initBasestationShowLayer('newMap');
      this.initHangarLayer();
      this.inspectionLayer = new BaseLayer({ name: "inspectionLayer" });
      this.$options.map3d.layerManager.add(this.inspectionLayer);
      this.setEditModel();
      this.initPopupLabels();
      this.initData();
      // 加载航线
      this.initRouteLine(this.$options.map3d, this.taskId)
      // 绑定见键盘事件
      addEvent(window, 'keydown', this.keydown)
    })
  },
  computed: {
    ...mapGetters(["map3d", "popupLine"]),
    realLineData() {
      return this.drawFlyPopupLine();
    },
    timeLabel() {
      return `${this._formatSeconds(
        this.percent.passTime
      )}/${this._formatSeconds(this.percent.total)}`;
    },
    useLive() {
      return this.dialogData.liveType === 1? 'VdieoLiveHWLLS' : 'VdieoLiveEasyplayer'
    },
    liveVideoVOList() {
      return this.dialogData &&
        this.dialogData.liveVideoVOList &&
        Array.isArray(this.dialogData.liveVideoVOList)?
        this.dialogData.liveVideoVOList:
        []
    }
  },
  methods: {
    keydown(e){
      // Alt + c 截屏
      if (e.altKey && e.keyCode === 67) {
        // 防止重复截屏
        if (this.screening) {
          return
        }
        this.screening = true
        this.$refs.videoLive.keydownScreenShot().finally(()=>{
          this.screening = false
        })
      }
    },
    /**
     * @description: 初始化数据
     * @param {*}
     * @return {*}
     * @author: wq
     */
    initData() {
      if (this.taskStatus === 3) {
        this.liveRoute();
      } else if (this.taskStatus === 4) {
        this.bufferData();
      }
      this.initPlanArea();
      this.requestInspectLabel(this.dialogData.projectId)
      this.initGreenLand()
      this.getNoFlyDataTwo(); // 加载禁飞区
      this.getAirportData(); // 加载机场
      this.getBasestationData(); // 加载基站
      this.getHangarData(this.dialogData); // 获取加载机库列表
    },

    liveRoute() {
      let guid = Cesium.createGuid().slice(0, 6).replace('-', 'A')
      let wsUrl = `${window.global.VUE_APP_WS_ROUTE_NEW}/${this.dialogData.airPlaneSn}-${guid}`;
      this.$options.flyRouteHook.initWebSocket({id: this.dialogData.id, entityIcon: this.XCIcon, 
        url: wsUrl, callback: this.updateLiveFlyRoute});
    },

    bufferData() {
      this.requestRoutePageData(this.tlfp, 1, 50);
    },

    requestRoutePageData(tlfp, page, size) {
      if (this.flyDataPages.find((x) => x.current === page)) {
        return;
      }

      const request = findHistoryRouteTrack({
        skipCount: size,
        skipNum: page,
        tlfp: tlfp,
      });

      request.then(
        (res) => {
          const { data, status } = res;
          if (status === "success" || !data || !data.data) {
            return;
          }
          const { current, pages, records, total } = data.data;

          if (this.flyDataBuffer.length <= 0) {
            const { firstTimeStamp, lastTimeStamp } = records[0];
            // 首次加载 计算总时间及间隔
            this.ready = true;
            this.percent.total = (lastTimeStamp - firstTimeStamp) / 1000;
            this.percent.totalPoint = total;
            this.percent.timeInterval = this.percent.total / total;
          }

          this.flyDataBuffer = this.flyDataBuffer.concat(records);
          this.flyDataPages.push(current);
          this.bufferPercent = this.flyDataBuffer.length / total;

          if (this.flyDataBuffer.length < total) {
            this.requestRoutePageData(tlfp, page + 1, size);
          }
        },
        () => {
          this.requestRoutePageData(tlfp, page, size);
        }
      );
    },

    updateLiveFlyRoute({id, entityIcon, data}) {
      const { yaw, pitch, roll, gs, alt, fieldHeight } = data;
      // 现阶段暂时不使用HUD需要时再打开
      // const airspeed = data.as
      // this.hud = Object.assign(this.hud, {
      //   uav_airespeed: airspeed !== undefined? airspeed: this.hud.uav_airespeed, // 表空速
      //   uav_groundspeed: gs !== undefined? gs: this.hud.uav_groundspeed, //地速
      //   alt: alt !== undefined? alt: this.hud.alt, //海拔高度
      //   // ranagefinder_distance: fieldHeight !== undefined? fieldHeight: this.hud.ranagefinder_distance, //激光测距高度
      //   relative_alt: fieldHeight !== undefined? fieldHeight: this.hud.relative_alt, //相对高度
      //   pitch: pitch !== undefined? pitch: this.hud.pitch, //俯仰
      //   roll: roll !== undefined? roll: this.hud.roll, //滚转
      //   heading: yaw !== undefined? yaw: this.hud.heading,
      // });

      let returnData = this.$options.flyRouteHook.findEntityById({id, entityIcon, iconSize: 60, lineColor: "#FFB100", 
        lineWidth: 2, polygonColor: "#FFB100", polygonAlpha: 0.45, data});
      this.lonArr = returnData.lonArr;
      if (this.follow) {
        this.$options.map3d.setCenter(returnData.lonArr);
      }
      store.commit("SET_POPUP_POSITION", returnData.options.position);
      store.commit("UPDATE_POPUP_LINE", { value: returnData.options.position, type: "end" });
      store.commit("SET_WS_TASK_INFO", data);
    },

    updateHistoryFlyRoute(lastIndex) {
      const last = this.flyDataBuffer[lastIndex];
      const uavId = "plane";
      let uav = this.$options.flyRouteHook.getEntityById(uavId);
      if (!last || !uav) {
        return;
      }
      this.cards = [];
      const d = this.flyDataBuffer.slice(0, lastIndex);

      let lastYaw = 0;
      const positions = d.map((x, idx) => {
        const { longitude, latitude, uavYaw, cardVoList } = x;
        const tempLon = Number(longitude);
        const tempLat = Number(latitude);

        if (idx === d.length - 1 && cardVoList) {
          let result = [];
          for (let i = 0; i < cardVoList.length; i += 1) {
            const temp = cardVoList[i];
            const has = this.cards.find(
              (n) => n.packageAlines === temp.packageAlines
            );
            if (has) {
              result.push({
                ...has,
                value: temp.value,
              });
            } else {
              const path = temp.packageAlines.split("/");
              result.push({
                ...temp,
                pack: path[0],
                key: path[1],
              });
            }
          }
          this.cards = result;
          lastYaw = Number(uavYaw);
          this.flyPopupUpdate(this.cards);
        }
        return Cesium.Cartesian3.fromDegrees(tempLon, tempLat);
      });

      uav.position = positions[positions.length - 1];
      uav.billboard.rotation = -Cesium.Math.toRadians(lastYaw || 0);
      uav.polyline.positions = new Cesium.CallbackProperty(function () {
        return positions;
      }, false);

      // store.commit("SET_POPUP_POSITION", positions[positions.length - 1]);
      // store.commit("UPDATE_POPUP_LINE", {
      //   value: positions[positions.length - 1],
      //   type: "end",
      // });
    },

    /**
     * @description: 动画触发
     * @param {*}
     * @return {*}
     * @author: wq
     */
    triggerAnimation() {
      this.trigger = !this.trigger;
      if (this.trigger) {
        const self = this;
        this.percent.handler = setInterval(() => {
          self.playRoute();
        }, this.percent.timeInterval * 1000);
        return;
      }
      if (this.percent.handler) {
        clearInterval(this.percent.handler);
      }
    },

    playRoute() {
      const newMessage = this.flyDataBuffer[this.percent.currentIndex];
      if (newMessage) {
        this.updateLiveFlyRoute(newMessage);
        this.percent.passTime += this.percent.timeInterval;
        this.percent.value = (this.percent.passTime / this.percent.total) * 100;
        this.percent.currentIndex += 1;
        return;
      }
      this.trigger = false;
      if (this.percent.handler) {
        clearInterval(this.percent.handler);
      }
    },

    /**
     * 初始化随动框字段
     */
    initPopupLabels() {
      //获取字典
      getDict({
        taskId: this.taskId,
      }).then((res) => {
        if (!res.data.data || res.data.data.cardJson == "") {
          return;
        }
        const datas = JSON.parse(res.data.data.cardJson);
        this.popupTitle = `${res.data.data.title} ${datas.idsn}`;
        if (!datas.cards) {
          return;
        }
        const card = datas.cards.cardContent.find((x) => {
          return x.vslType === 3;
        });
        if (!card) {
          return;
        }
        const items = card.items[0].items;
        for (let i = 0; i < items.length; i += 1) {
          const path = items[i].def.uri.split("/");
          // console.log(path)
          this.popupLabels.push({
            packAlias: items[i].def.uri,
            name: items[i].def.nameI18n,
            pack: path[0],
            key: path[1],
            unit: items[i].def.unit,
            value: 0,
          });
        }
      });
    },

    /**
     * 相机飞到目标对象
     */
    cameraFly(entity) {
      this.$options.map3d.flyTo(entity);
      this.ready = true;
    },

    /**
     * @description: 初始化任务区域
     * @param {*}
     * @return {*}
     * @author: wq
     */
    initPlanArea() {
      let kmlOptions = {
        camera: this.$options.map3d.scene.camera,
        canvas: this.$options.map3d.scene.canvas,
      };
      if (!this.taskRouteUrl) {
        return;
      }
      const geocachePromise = Cesium.KmlDataSource.load(
        this.taskAreaFileUrl,
        kmlOptions
      );
      const self = this;
      geocachePromise.then(function (dataSource) {
        self.$options.map3d.dataSources.add(dataSource);
        let entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          let entity = entities[i]; // 设置每个entity的样式
          entity.polygon.material = Cesium.Color.RED.withAlpha(0.3);
          entity.polygon.outline = true;
          entity.polygon.outlineColor = Cesium.Color.RED;
          entity.polygon.outlineWidth = 1;
          const coordinates = entity.polygon.hierarchy.getValue().positions;
          self.generatedNode(coordinates, "Plan", dataSource.entities);
        }
        self.$options.map3d.flyTo(dataSource);
      });
    },

    /**
     * @description: 检查图层是否存在
     * @param {*}
     * @return {*}
     * @author: wq
     */
    checkLayer(layerName) {
      let layer = this.$options.map3d.layerManager.find(layerName);
      if (!layer) {
        layer = new BaseLayer({ name: layerName });
        this.$options.map3d.layerManager.add(layer);
      } else {
        //layer.clear();
        layer.show = true;
      }
      return layer;
    },

    /**
     * @description: 设置Home点和返回点
     * @param {*}
     * @return {*}
     * @author: wq
     */
    initOriginPoint(coordinates) {
      let layer = this.checkLayer("flyPlan");
      const home = new Cesium.Entity({
        id: `home`,
        position: coordinates[0],
        billboard: {
          image: takeOffIcon,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          heightReference: Cesium.VerticalOrigin.TOP,
        },
      });
      layer.add(home);
      const back = new Cesium.Entity({
        id: `back`,
        position: coordinates[coordinates.length - 1],
        billboard: {
          image: landLineIcon,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          heightReference: Cesium.VerticalOrigin.TOP,
        },
      });
      this.backPoint = coordinates[coordinates.length - 1];
      layer.add(back);
    },

    /**
     * @description: 生成节点
     * @param {Array} coordinates 需要生成的线或面的坐标集合
     * @param {String} type 生成节点类型
     * @param {BaseLayer} layer 数据添加的图层
     * @return {*}
     * @author: wq
     */
    generatedNode(coordinates, type, layer) {
      for (let i = 0; i < coordinates.length; i++) {
        let imageURL = null;
        switch (type) {
          case "Line":
            imageURL = getFlyNodeNumberSVG(i);
            break;
          case "Plan":
            imageURL = getPlanNodeNumberSVG(i);
            break;
          default:
            break;
        }
        if (imageURL) {
          const NodeBiliboard = new Cesium.Entity({
            id: `fly${type}Node-` + i,
            position: coordinates[i],
            billboard: {
              image: imageURL,
              scale: 0.3,
              pixelOffset: new Cesium.Cartesian2(0, -15),
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              heightReference: Cesium.VerticalOrigin.TOP,
            },
          });
          layer.add(NodeBiliboard);
        }
      }
    },

    /**
     * 时间转换
     */
    _formatSeconds(value) {
      let result = parseInt(value);
      let h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);

      let res = "";
      if (h !== "00") res += `${h}h`;
      if (m !== "00") res += `${m}m`;
      res += `${s}s`;
      return res;
    },

    /**
     * hud数据更新
     */
    flyPopupUpdate(card) {
      for (let i = 0; i < card.length; i++) {
        const temp = card[i];
        if (this.hud[temp.key] != null && this.hud[temp.key] != undefined) {
          var n = Number(temp.value);
          if (!isNaN(n)) {
            if (temp.key == "relative_alt" || temp.key == "alt") {
              n = n / 1000;
              n = n.toFixed(2);
            }
            this.hud[temp.key] = n;
          } else {
            this.hud[temp.key] = temp.value;
          }
        }
      }
    },

    /**
     * 绘制随动框连接线
     */
    drawFlyPopupLine() {
      if (!this.popupLine) {
        return false;
      }
      const start = this.popupLine.start;
      const end = this.popupLine.end;
      if (!start || !end) {
        return false;
      }
      const cans = document.getElementById("map-linecanvas");
      if (!cans) {
        return false;
      }
      cans.width = cans.clientWidth;
      cans.height = cans.clientHeight;
      let startS = { x: start.left, y: start.top };
      if (start.left == null || start.top == null) {
        startS = this.$options.map3d.scene.cartesianToCanvasCoordinates(start);
        startS.y = startS.y;
      } else {
        //startS.x = startS.x + 250;
      }
      const endS = this.$options.map3d.scene.cartesianToCanvasCoordinates(end);
      const dom = this.$refs.uva.$el;
      const intrPoint = this.checkIntr(
        startS,
        endS,
        dom.offsetWidth,
        dom.offsetHeight
      );
      if (intrPoint) {
        startS = intrPoint;
      }
      let ctx = cans.getContext("2d");
      ctx.clearRect(0, 0, cans.width, cans.height);
      ctx.strokeStyle = "#ffff00"; //  样式：颜色
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(startS.x, startS.y);
      ctx.lineTo(endS.x, endS.y);
      ctx.stroke();
      ctx.closePath();
      return true;
    },

    /**
     * 获取kml文件
     */
    ajax(options) {
      options = options || {};
      // 默认是 GET 请求
      options.methods = options.methods.toUpperCase() || "GET";
      options.url = options.url || "";
      // 默认是异步请求
      options.async = options.async || true;
      options.data = options.data || {};
      options.success = options.success || function () {};
      options.faile = options.faile || function () {};

      let xhr = null;

      if (window.XMLHttpRequest) {
        xhr = new XMLHttpRequest();
      } else {
        xhr = new ActiveXObject("Microsoft.XMLHTTP");
      }

      let params = [];
      // 遍历请求参数对象，拼接请求参数
      for (let param in options.data) {
        params.push(param + "=" + options.data[param]);
      }
      // 给每个数组后面添加一个 &
      let requestData = params.join("&");
      // 请求类型
      let requestType = options.methods.toUpperCase();
      // 如果是 GET 请求
      if (requestType == "GET") {
        xhr.open(requestType, options.url + "?" + requestData, options.async);
        xhr.send();
      } else if (requestType == "POST") {
        // 如果是 POST 请求
        xhr.open(requestType, options.url, options.async);
        xhr.setRequestHeader(
          "Content-type",
          "application/x-www-form-urlencoded;charset=utf-8"
        );
        xhr.send(requestData);
      }

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          options.success(JSON.parse(xhr.responseText));
        } else if (xhr.status != 200) {
          options.faile("request error");
        }
      };
    },

    /**
     * 检查相交点
     */
    checkIntr(start, end, width, height) {
      const widthHalf = width / 2;
      const heigtHalf = height / 2;
      const verticalStrat = { x: start.x + widthHalf, y: start.y + heigtHalf }; //取浮动框中心点作为虚拟起点
      const topLeft = {
        x: verticalStrat.x - widthHalf,
        y: verticalStrat.y - heigtHalf,
      };
      const topRight = {
        x: verticalStrat.x + widthHalf,
        y: verticalStrat.y - heigtHalf,
      };
      const bottomRight = {
        x: verticalStrat.x + widthHalf,
        y: verticalStrat.y + heigtHalf,
      };
      const bottomLeft = {
        x: verticalStrat.x - widthHalf,
        y: verticalStrat.y + heigtHalf,
      };
      const rectLine = [
        {
          b: topLeft,
          c: topRight,
        },
        {
          b: topRight,
          c: bottomRight,
        },
        {
          b: bottomRight,
          c: bottomLeft,
        },
        {
          b: bottomLeft,
          c: topLeft,
        },
      ];
      let dis = 10000;
      let pt = null;
      for (let i = 0; i < rectLine.length; i++) {
        const intr = segmentsIntr(
          verticalStrat,
          end,
          rectLine[i].b,
          rectLine[i].c
        );
        if (intr) {
          const ll = lengthOfLine(intr, end);
          if (ll <= dis) {
            dis = ll;
            pt = intr;
          }
        }
      }
      return pt;
    },

    percentChange(per) {
      if (this.percent.handler) {
        this.trigger = false;
        clearInterval(this.percent.handler);
      }
      if (this.percent.value > per) {
        this.$refs.uva.clearPopup();
      }
      this.percent.value = per;
      this.percent.currentIndex = Math.floor(
        this.percent.totalPoint * (per / 100)
      );
      this.percent.passTime = this.percent.total * (per / 100);

      this.updateHistoryFlyRoute(this.percent.currentIndex);
    },

    failMessage() {
      this.$message.error(this.$emit('mission.failNotice'));
    },

    videoHandleClose() {
      this.$refs.videoLive.closeLive()
      this.showVideoHandle = false
    },

    videoHandleOpen() {
      this.showVideoHandle = true
    },
    
    requestInspectLabel(id) {
      getInspectionObjects({
        projectId: id,
        businessType: 1
      }).then(res=>{
        const code = res.data.code
        if (code === 200) {
          const data = res.data.data
          // 给编辑图层集合添加图层
          data && this.addInspectLabels(data)
        }
      })
    },

    addInspectLabels (data) {
      this.createInspectionsIntoLayer(data, this.inspectionLayer)
    },

    setEditModel(en = true) {
      // console.log('当前地图注册的交互事件：', en );
      const self = this;
      this.activeDraw = ''
      if (en === true) {
        this.drawFinish = true;
        this.$options.map3d._container.style.cursor = "default";
      }
      // 绘制完成后需要自动进入编辑模式 同时对部分元素
      let afterFinish =  (model, origin, selectFeature) => {
        let drawType = selectFeature && selectFeature.drawType
        this.selectedOilNode(selectFeature)
        this.$options.map3d.scene.screenSpaceCameraController.enableTilt = false;
        if (selectFeature && selectFeature.id.indexOf("uav-") > -1) {
          self.clickUav(selectFeature.id);
          return;
        }
        if (!model) {
          this.closeHangarPanel();
          return;
        }
        const primitive = model.parent ? model.parent : model;
        const orgId = primitive.orgId;
        drawType = primitive.drawType;
        // 若存在原始id 则代表需要调用 更新接口 而不是新增接口
        if (orgId && drawType !== "flyline") {
          const type = primitive.polygon
            ? "Polygon"
            : primitive.polyline
            ? "LineString"
            : "Point";
          const geojson = labelEntity2geojson(this.$options.map3d, primitive, type);
          const feature = {
            dirId: primitive.editLayer.id,
            geojson: JSON.stringify(geojson),
            tagName: geojson.properties.text,
            tagType: type,
            id: primitive.id,
          };
          // 后台接口弃用----lmw
          // const request = updateMapLabel(feature);
          // request.then((res) => {});
        }
        const type = model && model.drawType ? model.drawType : undefined;
        switch (type) {
          case "hangarPoint":
            this.selectedHangarNode(model);
            break;
        }
      }
      // 注册完交互事件的对用回调
      this.$options.map3d.triggerEditModel(en, {
        map: this.$options.map3d,
        vueHandler: self,
        afterFinish
      });
    }
  },
  beforeDestroy() {
    // 清理左侧面板数据
    this.$store.commit("CLEAR_WS_TASK_INFO");
    if (this.percent.handler) {
      clearTimeout(this.percent.handler);
    }
    const layer = this.checkLayer("flyLive");
    if (layer) {
      layer.clear();
    }
    this.$store.commit("SET_POPUP_POSITION", null);
    // 解除键盘绑定事件
    removeEvent(window, 'keydown', this.keydown)
    this.$options.flyRouteHook && this.$options.flyRouteHook.clearFlyRoute();
    this.$options.flyRouteHook = null;
    this.$options.map3d && this.$options.map3d.destroy();
  }
};
</script>

<style lang="scss" scoped>
.cesium-div {
  position: relative;
  height: 90%;
  overflow: hidden;
  .route-line{
    position: absolute;
    top: 15px;
    left: 11px;
    height: 39px;
    // width: 56px;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    color: #FFFFFF;
    line-height: 39px;
    padding: 0px 5px;
    cursor: pointer;
  }
  .route-task-line{
    position: absolute;
    top: 15px;
    left: 85px;
    height: 39px;
    color: #FFFFFF;
    width: 100px;
    line-height: 39px;
    /deep/ .el-checkbox__label{
      color: #FFFFFF;
    }
    .Violet-line {
      display: inline-block;
      vertical-align: middle;
      width: 16px;
      height: 3px;
      background-color: #EC3FF7;
      margin-right: 4px;
    }
  }
  .hover-tip {
    position: absolute;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFFFFF;
    padding: 8px;
    border-radius: 4px;
    &-triangle{
      position: absolute;
      box-sizing: border-box;
      border: 10px solid;
      border-color: transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8) transparent;
      left: 50%;
      top: 100%;
      transform: translateX(-50%)  translateY(-51%) rotateZ(45deg);
    }
    &-item{
      word-wrap: break-word
    }
  }
}
#cesiumContainer_newMap,
#map-linecanvas {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
#map-linecanvas {
  position: absolute;
  pointer-events: none;
  top: 0;
  z-index: 101;
}
.hud-plane {
  position: absolute;
  right: 1%;
  top: 1%;
  transform: scale(0.6);
  transform-origin: right top;
}
.video-plane-box {
  box-sizing: border-box;
  position: absolute;
  right: 1%;
  width: 360px;
  height: 245px;
  top: 10px;
  padding: 9px 10px 16px 13px;
  background: #0D1013C4;
  border-radius: 3px;
  .video-plane-title {
    height: 19px;
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 19px;
    box-sizing: border-box;
    border-bottom: 3px #387BED solid;
    margin-bottom: 10px;
  }
  .close-handle {
    position: absolute;
    right: 5px;
    color: #FFF;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    background: #3C3C3C;
    border-radius: 10px;
    &:hover{
      background: #409EFF;
    }
  }
  .video-plane {
    width: 100%;
    margin-top: 10px;
    height: calc(100% - 30px);
  }
}
.hide-plane-box {
  padding: 0;
  background-color: transparent;
}
.nodes {
  display: none;
  position: absolute;
  right: 20px;
  width: 328px;
  height: 50%;
  bottom: 1%;
}
.full-height {
  height: 98%;
  top: 1%;
}
.cesium-widget-credits {
  display: none !important;
  visibility: hide !important;
}
.player-controller {
  position: absolute;
  bottom: 2%;
  left: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play-btn {
  width: 56px;
  height: 56px;
}
.statu-play {
  background-image: url("../../assets/img/map/start.png");
}
.statu-stop {
  background-image: url("../../assets/img/map/stop.png");
}
.flypopup {
  z-index: 100;
}
</style>
