var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "el-dialog",
        {
          staticClass: "task-detail-dialog-main",
          attrs: {
            title: _vm.$t("mission.flightMissionDetails"),
            visible: _vm.dialogVisible,
            width: "80%",
            fullscreen: true,
            "append-to-body": true,
            "before-close": _vm.closeDialog,
            "destroy-on-close": "",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("div", { staticClass: "task-fly" }, [
                _c("div", { staticClass: "task-info" }, [
                  _c("div", { staticClass: "task-info-title" }, [
                    _c("i", {
                      staticClass: "el-icon-arrow-left",
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.closeDialog },
                    }),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.dialogData.taskStatus === 4
                              ? _vm.$t("mission.history")
                              : _vm.$t("mission.MissionDetails")
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm.dialogData.taskStatus === 3 &&
                    _vm.permission.project_task_scan
                      ? _c("i", {
                          staticClass: "el-icon-share right-relation",
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.openShare },
                        })
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "task-info-content" }, [
                    _c("div", { staticClass: "task-info-content-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("mission.flightMissionDetails")) +
                          "\n          "
                      ),
                    ]),
                    _c("div", { staticClass: "task-info-content-box" }, [
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("mission.name")) + ":"),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.dialogData.taskName))]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("mission.device")) + ":"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.dialogData.equipmentName)),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("mission.team")) + ":"),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.dialogData.teamName))]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("mission.operator")) + ":"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.dialogData.flyOperatorName)),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("startTime")) + ":")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.dialogData.realBeginTime)),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("endTime")) + ":")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.dialogData.realFinishTime)),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-info-content-title" }, [
                      _vm._v(_vm._s(_vm.$t("mission.flightInformation"))),
                    ]),
                    _c("div", { staticClass: "task-info-content-box" }, [
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("longitude")) + ":")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.wsTaskInfo.lon
                                ? _vm.wsTaskInfo.lon.toFixed(7)
                                : "--"
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("latitude")) + ":")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.wsTaskInfo.lat
                                ? _vm.wsTaskInfo.lat.toFixed(7)
                                : "--"
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("height")) + ":")]),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.wsTaskInfo.fieldHeight
                                  ? _vm.wsTaskInfo.fieldHeight.toFixed(1) + "m"
                                  : _vm.wsTaskInfo.fieldHeight === 0
                                  ? 0 + "m"
                                  : "--"
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("gs")) + ":")]),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.wsTaskInfo.gs
                                  ? (typeof _vm.wsTaskInfo.gs === "number"
                                      ? _vm.wsTaskInfo.gs.toFixed(1)
                                      : _vm.wsTaskInfo.gs) + "m/s"
                                  : _vm.wsTaskInfo.gs === 0
                                  ? 0 + "m/s"
                                  : "--"
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("CurrentBatteryLevel")) + ":"),
                        ]),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.wsTaskInfo.pwrLeft
                                  ? _vm.wsTaskInfo.pwrLeft + "%"
                                  : _vm.wsTaskInfo.pwrLeft === 0
                                  ? "0%"
                                  : "--"
                              ) +
                              "\n              "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("Homedist")) + ":")]),
                        _c("span", [_vm._v(_vm._s(_vm.Homedist))]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("RealTimeMileage")) + ":"),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.distOnAir))]),
                      ]),
                      _c("div", { staticClass: "item-line" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("FlightTime")) + ":"),
                        ]),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.tmOnAir) +
                              "\n              "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "task-map-box" },
                  [
                    _c(_vm.taskComponent, {
                      tag: "component",
                      staticStyle: { height: "100%" },
                      attrs: {
                        dialogData: _vm.dialogData,
                        taskAreaFileUrl: _vm.dialogData.taskAreaFileUrl,
                        taskRouteUrl: _vm.dialogData.taskRouteUrl,
                        taskStatus: _vm.dialogData.taskStatus,
                        taskId: _vm.dialogData.id,
                        tlfp: _vm.dialogData.tlfp,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: {
                width: "400px",
                title: "分享直播",
                visible: _vm.shareShow,
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.shareShow = $event
                },
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      border: "1px #1890FF solid",
                      "background-color": "rgba(145, 213, 255, 0.4)",
                      padding: "16px",
                      "border-radius": "4px",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "16px",
                        "vertical-align": "bottom",
                      },
                      attrs: { src: "/img/map/detail.svg", alt: "" },
                      on: { click: _vm.copy },
                    }),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          "margin-left": "10px",
                        },
                      },
                      [_vm._v("请点击鼠标右键复制直播二维码进行分享。")]
                    ),
                  ]
                ),
                _c("div", {
                  ref: "qrCodeDiv",
                  staticStyle: { "margin-left": "80px", "margin-top": "32px" },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }