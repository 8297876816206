<template>
  <basic-container>
    <el-dialog
      :title="$t('mission.flightMissionDetails')"
      :visible.sync="dialogVisible"
      width="80%"
      class="task-detail-dialog-main"
      :fullscreen="true"
      :append-to-body="true"
      :before-close="closeDialog"
      destroy-on-close
      :modal="false">
      <div v-if="dialogVisible" class="task-fly">
        <div class="task-info">
          <div class="task-info-title">
            <i 
              style="cursor: pointer"
              class="el-icon-arrow-left"
              @click="closeDialog"
              >
            </i>
            <span>
              {{dialogData.taskStatus === 4? $t('mission.history'): $t('mission.MissionDetails')}}
            </span>
            <i
              style="cursor: pointer"
              v-if="dialogData.taskStatus === 3&&permission.project_task_scan"
              @click="openShare"
              class="el-icon-share right-relation">
            </i>
          </div>
          <div class="task-info-content">
            <div class="task-info-content-title">
              {{$t('mission.flightMissionDetails')}}
            </div>
            <div class="task-info-content-box">
                <div class="item-line">
                  <span>{{$t('mission.name')}}:</span>
                  <span>{{ dialogData.taskName }}</span>
                </div>
                <div class="item-line">
                  <span>{{$t('mission.device')}}:</span>
                  <span>{{ dialogData.equipmentName }}</span>
                </div>
                <div class="item-line">
                  <span>{{$t('mission.team')}}:</span>
                  <span>{{ dialogData.teamName }}</span>
                </div>
                <div class="item-line">
                  <span>{{$t('mission.operator')}}:</span>
                  <span>{{ dialogData.flyOperatorName }}</span>
                </div>
                <div class="item-line">
                  <span>{{$t('startTime')}}:</span>
                  <span>{{ dialogData.realBeginTime }}</span>
                </div>
                <div class="item-line">
                  <span>{{$t('endTime')}}:</span>
                  <span>{{ dialogData.realFinishTime }}</span>
                </div>
            </div>
            <div class="task-info-content-title">{{$t('mission.flightInformation')}}</div>
            <div class="task-info-content-box">
              <div class="item-line">
                <span>{{$t('longitude')}}:</span>
                <span>{{ wsTaskInfo.lon? wsTaskInfo.lon.toFixed(7): '--' }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('latitude')}}:</span>
                <span>{{ wsTaskInfo.lat? wsTaskInfo.lat.toFixed(7): '--' }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('height')}}:</span>
                <span>
                  {{ 
                    wsTaskInfo.fieldHeight? ( 
                      wsTaskInfo.fieldHeight.toFixed(1) + 'm'
                    ): 
                    (
                      wsTaskInfo.fieldHeight === 0?  0 + 'm': '--'
                    ) 
                  }}
                </span>
              </div>
              <div class="item-line">
                <span>{{$t('gs')}}:</span>
                <span>
                  {{ 
                    wsTaskInfo.gs? 
                      (typeof wsTaskInfo.gs === 'number'? wsTaskInfo.gs.toFixed(1): wsTaskInfo.gs) + 'm/s':
                      (wsTaskInfo.gs === 0?  0 + 'm/s': '--') 
                  }}
                </span>
              </div>
              <div class="item-line">
                <span>{{$t('CurrentBatteryLevel')}}:</span>
                <span>
                  {{ 
                    wsTaskInfo.pwrLeft? `${wsTaskInfo.pwrLeft}%` : 
                      (wsTaskInfo.pwrLeft === 0?  `0%`: '--') 
                  }}
                </span>
              </div>
              <div class="item-line">
                <span>{{$t('Homedist')}}:</span>
                <span>{{ Homedist }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('RealTimeMileage')}}:</span>
                <span>{{ distOnAir }}</span>
              </div>
              <div class="item-line">
                <span>{{$t('FlightTime')}}:</span>
                <span>
                  {{ tmOnAir }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="task-map-box">
          <component
            style="height: 100%"
            :is="taskComponent"
            :dialogData="dialogData"
            :taskAreaFileUrl="dialogData.taskAreaFileUrl"
            :taskRouteUrl="dialogData.taskRouteUrl"
            :taskStatus="dialogData.taskStatus"
            :taskId="dialogData.id"
            :tlfp="dialogData.tlfp">
          </component>
        </div>
      </div>
      <el-dialog
        width="400px"
        title="分享直播"
        :visible.sync="shareShow"
        append-to-body>
        <div>
          <div style="border: 1px #1890FF solid; background-color: rgba(145, 213, 255, 0.4); padding: 16px; border-radius: 4px">
            <img @click="copy" style="width: 16px; vertical-align: bottom;" src="/img/map/detail.svg" alt="">
            <span style="font-size: 14px; margin-left: 10px;">请点击鼠标右键复制直播二维码进行分享。</span>
          </div>
          <div style="margin-left: 80px; margin-top: 32px" ref="qrCodeDiv"></div>
        </div>
      </el-dialog>
    </el-dialog>
  </basic-container>
</template>

<script>
// 以后都是用IOT 注释代码展示保留
// import Map from "@/components/map/map"; 
// import Route from "@/components/map/route";
// import HistoryRoute from "@/components/map/history-route";
import NewMap from "@/components/map/newMap"; //这次同一版本的websocket的接入
import NewHistoryRoute from "@/components/map/new-history-route";
import { mapGetters } from "vuex";
import QRCode from "qrcodejs2";
import { findEquipmentModel } from "@/api/map/index"

export default {
  components: {
    // Map,
    // Route,
    // HistoryRoute,
    NewMap,
    NewHistoryRoute
  },
  data() {
    return {
      shareShow: false
    }
  },
  props: {
    dialogData: {
      type: Object,
      default() {
        return {
          taskName: "",
          teamName: "",
          taskTypeDesc: "",
          taskSite: "",
          equipmentName: "",
          flyOperatorName: "",
          planBeginTime: "",
          planFinishTime: "",
          taskAreaFileUrl: "",
          taskRouteUrl: "",
          taskStatus: "",
          id: "",
          videoUrl: "",
          projectName:"",
          projectId: ""
        };
      },
    },
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["wsTaskInfo", 'permission']),
    tmOnAir(){
      const time = this.wsTaskInfo.tmOnAir;
      let res = '00:00:00';
      if (typeof time === 'number') {
        let second = time % 60;
        let min = time % 3600;
        let hour = parseInt(time / 3600)

        let secondStr = second.toString()
        if (secondStr.length < 2) {
          secondStr = '0' + secondStr
        }

        let minStr = parseInt((min - second) / 60).toString()
        if (minStr.length < 2) {
          minStr = '0' + minStr
        }
        
        let hourStr = hour.toString()
        if (hourStr.length < 2) {
          hourStr = '0' + hourStr
        }

        res = `${hourStr}:${minStr}:${secondStr}`
      }
      return res
    },
    distOnAir() {
      let dis = 0 + 'km'
      if (this.wsTaskInfo.distOnAir && this.wsTaskInfo.distOnAir > 0) {
        dis = (this.wsTaskInfo.distOnAir/ 1000).toFixed(2) + 'km'
      }
      return dis
    },
    Homedist() {
      if (this.wsTaskInfo && this.wsTaskInfo.homedist !== undefined 
        && this.wsTaskInfo.homedist !== null && this.wsTaskInfo.homedist !== '') {
        return `${this.wsTaskInfo.homedist}km`
      }
      return '--'
    },
    taskComponent() {
      var component = this.dialogData.taskStatus === 4 ? 'NewHistoryRoute': 'NewMap'
      return component
    }
  },
  methods: {
    closeDialog(done) {
      this.$emit("closeDialog", done);
    },
    openShare() {
      this.shareShow = true
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = ''
        console.log(this.dialogData)
        findEquipmentModel({
          sn: this.dialogData.airPlaneSn
        }).then(res=>{
          if (res.data.code === 200) {
            this.text = `${window.global.VUE_APP_H5_LOCATION}?imgURL=${res.data.data.modelSelectIcon}&taskId=${this.dialogData.id}&domain=${window.global.VUE_APP_WS_ROUTE_NEW}`
            new QRCode(this.$refs.qrCodeDiv, {
              text: this.text, //url地址  文本等需要转换为二维码的内容
              width: 200,
              height: 200,
              colorDark: "#000000", //二维码颜色
              colorLight: "#ffffff", //二维码背景色
              correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
            });
          }
        })
      });
    },
    copy() {
      this.$Clipboard({
        text: this.text,
      })
      .then(() => {
        // this.$message.success("复制分享链接成功!");
      })
      .catch(() => {
        // this.$message.error("复制分享链接失败");
      });
    }
  },
};
</script>

<style lang="scss">
.task-detail-dialog-main {
  height: 100%;
  .is-fullscreen {
    background-color: #000;
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body {
      padding: 10px 0px;
      height: 100%;
      box-sizing: border-box;
      background: #18181B;
      .task-fly{
        overflow: hidden;
        height: 100%;
        .task-info {
          width: 290px;
          height: 100%;
          display: inline-block;
          vertical-align: middle;
          .task-info-title{
            height: 70px;
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
            background: #1E222A;
            border-bottom: 2px #030303 solid;
            line-height: 20px;
            box-sizing: border-box;
            padding: 25px 23px;
            span {
              margin-left: 24px;
            }
            .right-relation {
              float: right;
            }
          }
          .task-info-content{
            height: calc(100% - 70px);
            background: #1E222A;
            overflow: auto;
            padding: 0px 16px 10px 16px;
            box-sizing: border-box;
            .task-info-content-title {
              padding-left: 11px;
              font-size: 14px;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 19px;
              margin-top: 24px;
            }
            .task-info-content-box {
              margin-top: 11px;
              background: #00000057;
              border: 1px solid #272729;
              padding: 3px 9px 10px 18px;
              .item-line {
                margin-top: 13px;
                font-size: 14px;
                font-family: MicrosoftYaHeiUI;
                color: #FFFFFF;
                line-height: 18px;
                display: flex;
                justify-content: space-between;
                :first-child{
                  opacity: 0.6;
                }
              }
            }
          }
        }
        .task-map-box {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 290px);
          height: 100%;
        }
      }
      
    }
  }
}
</style>
