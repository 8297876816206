var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialBord" }, [
    _c("i", { staticClass: "mark" }, [
      _vm._v("\n    " + _vm._s(_vm.fix(_vm.angle, 2)) + "\n  "),
    ]),
    _c(
      "ul",
      { attrs: { id: "dialBordBox" } },
      [
        _vm._l(_vm.mark, function (item, index) {
          return [
            item || item == 0
              ? _c(
                  "li",
                  { key: index, style: { transform: _vm.makeScale(index) } },
                  [
                    index % 2 === 1
                      ? _c("span", [_vm._v(_vm._s(item))])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        }),
        _c(
          "div",
          { staticClass: "fix" },
          [_c("Pitching", { attrs: { pitch: _vm.pitch } })],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }