<template>
  <div class="orientation">
    <!-- <input type="text" v-model="value" /><button @click="make">点击</button> -->
    <ul id="orientationBox">
      <template v-for="(item, index) in list">
        <li
          v-if="item % 5 === 0"
          :key="index"
          :class="item % 15 === 0 ? '' : 'short'"
        >
          <span class="scale" v-if="item % 15 === 0">{{
            orientationMap[item] || item
          }}</span>
        </li>
      </template>
    </ul>
    <i class="mark">{{ fix(orientation, 2) }}</i>
  </div>
</template>

<script>
import { toPrecision } from "@/util/util";
export default {
  mounted() {
    this.init();
    // this.timer = setInterval(() => {
    //   this.orientation = this.test[Math.round(Math.random() * 10)];
    //   this.makeScroll();
    // }, 2000);
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
  // computed: {
  //   heading() {
  //     return this.$store.state.screenLeftData.heading;
  //   },
  // },
  props: {
    heading: Number,
  },
  watch: {
    heading: function (val) {
      if (val) {
        this.orientation = val;
        this.makeScroll();
      }
    },
  },
  data() {
    return {
      list: [],
      orientation: 0,
      previous: 0, //暂存
      left: 0,
      prefix: 0,
      crisis: 75,
      tmp: [],
      orientationMap: {
        0: "N",
        45: "NE",
        90: "E",
        135: "SE",
        180: "S",
        225: "SW",
        270: "W",
        325: "NW",
        360: "N",
      },
      test: [15, 320, 230, 22, 28, 19, 356, 333, 12, 315, 8, 16, 28],
      value: 0,
    };
  },
  methods: {
    fix(val, precision) {
      if (!val) return "0";
      return toPrecision(val, precision);
    },
    make() {
      this.orientation = this.value;
      this.makeScroll();
    },
    makeScroll() {
      const box = document.querySelector("#orientationBox");
      let difference = Number(this.orientation - this.previous); //当前坐标和之前坐标的差值
      if ((difference > 0 && difference <= 270) || difference < -270) {
        //右
        if (difference < -270) {
          difference = 360 - Math.abs(difference);
        }
      } else if (difference < 0 || difference > 270) {
        //左
        if (difference > 270) {
          difference = Math.abs(difference) - 360;
        }
      }
      this.left = this.left + difference; //移动了多少角度
      if (this.orientation <= 120 && this.left <= -120) {
        // console.log("向右修正");
        this.left = this.left + 360;
      }
      if (this.orientation >= 240 && this.left >= 240) {
        // console.log("向左修正");
        this.left = this.left - 360;
      }
      box.style.left = `calc(50% - ${this.left * 3.4}px)`;
      this.toLeft = false;
      this.toRight = false;
      this.previous = this.orientation;
    },
    init() {
      const t = [];
      for (let i = 5; i <= 355; i += 5) {
        t.push(i);
      }
      this.tmp = t;
      const list = [...t, 0, ...t];
      this.list = list;
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #4dfcff;
ul {
  margin: 0;
  padding: 0;
}
.orientation {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;
  .mark {
    padding: 0 10px;
    height: 20px;
    background: $color;
    line-height: 20px;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    left: 50%;
    top: 26px;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    text-align: center;
    padding: 0 10px;
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-bottom: 6px solid $color;
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  ul {
    transition: linear 0.1s;
    position: absolute;
    // border-top: 2px solid $color;
    position: absolute;
    left: calc(50%);
    top: 0px;
    transform: translate(-50%, 0);
    display: flex;
    li {
      width: 1px;
      height: 10px;
      background: $color;
      margin: 0px 8px;
      position: relative;
      .scale {
        font-size: 16px;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        white-space: nowrap !important;
        // top: 20px;
        // left: -4px;
      }
    }
    .short {
      height: 6px;
    }
    .big {
      height: 12px;
    }
  }
}
</style>
