var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orientation" }, [
    _c(
      "ul",
      { attrs: { id: "orientationBox" } },
      [
        _vm._l(_vm.list, function (item, index) {
          return [
            item % 5 === 0
              ? _c(
                  "li",
                  { key: index, class: item % 15 === 0 ? "" : "short" },
                  [
                    item % 15 === 0
                      ? _c("span", { staticClass: "scale" }, [
                          _vm._v(_vm._s(_vm.orientationMap[item] || item)),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
    _c("i", { staticClass: "mark" }, [
      _vm._v(_vm._s(_vm.fix(_vm.orientation, 2))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }