<template>
  <div class="new-history-map-box">
    <div id="cesiumContainer_history"></div>
    <div
      ref="footer"
      class="footer"
      >
      <div class="play-controller">
        <div :style="`width:${processingTextWidth}px`">{{ `${passedTime}/${totalTime}` }}</div>
        <span @click="triggerPlay" class="video-play">
          <i v-if="loading" class="el-icon-loading" ></i>
          <i v-else-if="play" class="el-icon-video-pause"></i>
          <i v-else class="el-icon-video-play"></i>
        </span>
        <div
          @click="setControllerPosition"
          ref="processing"
          class="processing">
          <el-progress
            :percentage="percentage"
            :show-text="false"
          ></el-progress>
          <div
            :style="`left:${percentage}%`"
            class="processing-controller"></div>
        </div>
      </div>
      <div class="tool-controller">
      </div>
    </div>
    <!-- 基站悬浮窗 -->
    <BaseStationPop ref="BaseStationPop" :id="'newhistory-route'" :detailData="baseStationDetail" />
    <!-- 机库详情弹窗 -->
    <HangarDetails v-if="hangarShow" ref="hangarDetails" :show.sync="hangarShow" :baseData="hangarBaseData" 
      :detailsData="hangarDetailsData" :videoData="hangarVideoData" @closeDetails="closeHangarPanel" />
  </div>
</template>

<script>
import Map3d from "../cesium/js/Map3d";
import { getUavDataModel, findEquipmentModel } from "@/api/map/index";
import  { mapActions } from 'vuex'
import PlaneIcon from "@/assets/img/map/XC_64x64_.png";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import { getInspectionObjects } from "@/api/project/label";
// 加载巡检数据
import oilInspectionVue from '@/components/cesium/mixins/oilInspection.vue';
import noFlyZoneShowVue from "@/components/cesium/mixins/noFlyZoneShow.vue";
import airportShowVue from "@/components/cesium/mixins/airportShow.vue";
import basestationShowVue from "@/components/cesium/mixins/basestationShow.vue";
import hangarVue from "@/components/cesium/mixins/hangar.vue";
import hangarShowVue from "@/components/cesium/mixins/hangarShow.vue";
import Node from '@/components/cesium/js/node/index';
import liveFlyRouteHook from '@/components/cesium/js/hooks/liveFlyRouteHook';

export default {
  name: "cesiumViewer",
  mixins: [ oilInspectionVue, noFlyZoneShowVue, airportShowVue, basestationShowVue, hangarVue, hangarShowVue ],
  props: {
    dialogData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // 轨迹是否在加载中
      loading: true,
      // 是否播放
      play: false,
      // 播放条显示进度
      percentage: 0,
      // 是否开启拖动
      controlDroping: false,
      // 实际播放进度
      propessControler: 0,
      // 当前i时间显示宽度
      processingTextWidth: 150,
      startTime: new Date().getTime() - 1000 * 60 * 60,
      endTime: new Date().getTime(),
      labelLayer: new BaseLayer({ name: "labelLayer" }),
      // 当缓存数据
      currentList: [],
      // 当前缓存数据最大条数
      maxlength: 20,
      // 当前播放的数据的位置
      currentIndex: 0,
      // 目前支持2d，3d以后再说
      // 2d、3d 模式
      viewModel: Cesium.SceneMode.SCENE3D,
      trackLoading: false,
      PlaneIcon,
      // 巡检对象图层
      inspectionLayer: []
    };
  },
  computed: {
    passedTime(){
      let total = this.endTime - this.startTime
      let current = this.percentage * total / 100
      let date = new Date(parseInt(current));
      let Hour = date.getHours() >= 8? date.getHours() - 8: 24 - date.getHours()
      let Minute = date.getMinutes()
      let Sechond = date.getSeconds()
      Hour = Hour < 10? '0' + Hour: Hour
      Minute = Minute < 10? '0' + Minute: Minute
      Sechond = Sechond < 10? '0' + Sechond: Sechond
      let  GMT =  (Hour === '00'?  '': Hour + ':') + Minute + ':' + Sechond;
      return GMT
    },
    totalTime(){
      let date = new Date(parseInt(this.endTime - this.startTime));
      let Hour = date.getHours() >= 8? date.getHours() - 8: 24 - date.getHours()
      let Minute = date.getMinutes()
      let Sechond = date.getSeconds()
      Hour = Hour < 10? '0' + Hour: Hour
      Minute = Minute < 10? '0' + Minute: Minute
      Sechond = Sechond < 10? '0' + Sechond: Sechond
      let  GMT =   Hour + ':' + Minute + ':' + Sechond;
      return GMT
    },
  },
  mounted() {
    this.$options.flyRouteHook = liveFlyRouteHook();
    findEquipmentModel({
      sn: this.dialogData.airPlaneSn
    }).then(res=>{
      if (res.data.code === 200) {
        const data = res.data.data
        if (data && data.modelIcon) {
          this.PlaneIcon = data ? data.modelIcon : this.PlaneIcon
        }
      }
    }).finally(()=>{
      this.initViewer();
      this.startPlay();
      // 加载巡检对象
      this.inspectionLayer = new BaseLayer({ name: "inspectionLayer" });
      this.$options.map3d.layerManager.add(this.inspectionLayer);
      this.requestInspectLabel(this.dialogData.projectId) // 加载巡检对象数据
      this.getNoFlyDataTwo(); // 加载禁飞区
      this.getAirportData(); // 加载机场
      this.getBasestationData(); // 加载基站
      this.getHangarData(this.dialogData); // 获取加载机库列表
    })
  },
  methods: {
    ...mapActions([
      'setWsTaskInfo'
    ]),

    footerMouseUp(evt) {
      if (this.controlDroping) {
        // 重新设置进度条的位置
        // this.setControllerPosition(evt)
        // 重新拉去轨迹数据
        // TODO
        this.controlDroping = false
      }
    },

    setControllerPosition(evt) {
      this.loading = true
      this.clear()
      this.endPlay()
      var left = evt.clientX
      var footer = this.$refs.footer.getBoundingClientRect()
      // 鼠标相对footer的Dom元素的位置
      var leftFooter = left - footer.left
      // 鼠标相对进度条的位置
      var leftPro = leftFooter - 52 - this.processingTextWidth
      if (leftPro < 0) {
        this.percentage = 0
      } else if (footer.width < leftFooter + 120 ) {
        this.percentage = 100
      } else {
        this.percentage = leftPro * 100 / (footer.width - 52 - this.processingTextWidth - 120) 
      }
      if (this.trackLoading) {
        setTimeout(()=>{
          this.setControllerPosition(evt)
        }, 500)
        return
      }
      
      setTimeout(()=>{
        this.currentList = []
        this.currentIndex = 0
        var stamp = (this.endTime - this.startTime) * this.percentage / 100 + this.startTime
        this.startPlay(stamp)
      }, 500)
    },

    // 初始化页面
    initViewer() {
      this.$options.map3d = new Map3d("cesiumContainer_history", {
        sceneMode: this.viewModel,
      });
      this.initLayer();
      this.setEditModel();
      if (this.$options.timer) {
        clearTimeout(this.$options.timer)
        this.$options.timer = null
      }
      this.currentIndex = 0
    },

    // 初始化图层
    initLayer() {
      this.$options.map3d.layerManager.add(this.labelLayer);
      this.$options.flyRouteHook.setMap({map3d: this.$options.map3d, flylineLayerID: "flylineLayer", 
        showVisualPolygon: true});
      this.initNoflyShowLayer();
      this.initAirportShowLayer();
      this.initBasestationShowLayer('newhistory-route');
      this.initHangarLayer();
    },

    // 开始和停止播放
    triggerPlay() {
      this.play = !this.play;
      if (this.play) {
        this.startPlay()
      } else {
        this.endPlay()
      }
    },

    //开始播放
    startPlay(stamp) {
      this.updateTrack(stamp)
    },
    // 结束播放
    endPlay(){
      if (this.$options.timer) {
        clearTimeout(this.$options.timer)
        this.$options.timer = null
      }
    },

    // 开始加载轨迹数据
    updateTrack(stamp) {
      if (!this.$options.timer) {
        clearTimeout(this.$options.timer)
        this.$options.timer = null
      }
      if (this.currentList.length > 0) {
        // 当前缓存数据中有轨迹流
        if ( this.currentList.length -  this.currentIndex < this.maxlength / 4) {
          this.loadTrack()
        } else {
          this.updateFlyLine()
        }
      }else {
        this.loadTrack(stamp)
      }
      this.$options.timer = setTimeout(this.updateTrack, 1000);
    },

    // 加载轨迹片段
    loadTrack(stamp) {
      if ( this.trackLoading ) {
        return
      }
      this.trackLoading = true
      // 检查时间戳，根据现在播放帧位置获取时间戳开始时间
      let _stamp = undefined
      if (stamp) {
        _stamp = stamp
      } else {
        _stamp = this.currentList.length > 0? this.currentList[this.currentList.length -1].currentTime: undefined
      }
      getUavDataModel({
          size: this.maxlength / 2,
          startTime: _stamp,
          taskID: this.dialogData.id
        }).then(res=>{
          if (res.data.code === 200) {
            let data = res.data.data
            data.length = this.maxlength / 2
            this.startTime = data[0].taskStartTime
            this.endTime = data[0].taskEndTime
            if ( this.currentList.length === 0) {
              this.currentList = this.currentList.concat(data)
            } else if (this.currentList.length === this.maxlength / 2) {
              this.currentList = this.currentList.concat(data)
            } else {
              this.currentList.splice(0, this.maxlength / 2)
              this.currentList = this.currentList.concat(data)
              this.currentIndex = this.currentIndex - this.maxlength / 2
            }
          }
        }).finally(()=>{
          this.trackLoading = false
          this.loading = false
        })
    },

    // 更新飞行轨迹
    updateFlyLine() {
      if (this.currentList.length === 0) {
        return
      }
      try {
        let data = this.currentList[this.currentIndex]
        this.setWsTaskInfo(data)
        const id = this.dialogData.id;
        let returnData = this.$options.flyRouteHook.findEntityById({id, entityIcon: this.PlaneIcon, iconSize: 60, 
          lineColor: "#FF3100", lineWidth: 2, polygonColor: "#FF3100", polygonAlpha: 0.45, data});
        if (returnData.isUpDate) {
          this.play = true
        }
        this.percentage = (data.currentTime - this.startTime) * 100 / (this.endTime - this.startTime)
      } catch (error) {
        console.log(error);
      } finally {
        this.currentIndex++
      }
    },

    // 清理图层
    clear() {
      this.$options.flyRouteHook.clearFlylineLayer();
      this.labelLayer.clear();
    },

    updateProcessing(start, end, current) {
      // const total = end - start;
      // const passed = current - start;
      // this.percentage = (passed / total) * 100;
      // this.totalTime = this.formatSeconds(total / 1000);
      // this.passedTime = this.formatSeconds(passed / 1000);
    },

    // 加载巡检对象
    requestInspectLabel(id) {
      getInspectionObjects({
        projectId: id,
        businessType: 1
      }).then(res=>{
        const code = res.data.code
        if (code === 200) {
          // 给编辑图层集合添加图层
          res.data.data && this.addInspectLabels(res.data.data)
        }
      })
    },

    addInspectLabels (data) {
      this.createInspectionsIntoLayer(data, this.inspectionLayer)
    },

    setEditModel(en = true) {
      // console.log('当前地图注册的交互事件：', en );
      const self = this;
      this.activeDraw = ''
      this.$options.map3d._container.style.cursor = "default";
      // 绘制完成后需要自动进入编辑模式 同时对部分元素
      let afterFinish =  (model, origin, selectFeature) => {
        !model && this.closeHangarPanel();
        const type = model && model.drawType ? model.drawType : undefined;
        switch (type) {
          case "hangarPoint":
            this.selectedHangarNode(model);
            break;
        }
      }
      // 注册完交互事件的对用回调
      this.$options.map3d.triggerEditModel(en, {
        map: this.$options.map3d,
        vueHandler: self,
        afterFinish
      });
    }
  },
  beforeDestroy() {
    if(this.$options.timer){
      clearTimeout(this.$options.timer)
      this.$options.timer = null
    }
    this.$options.flyRouteHook && this.$options.flyRouteHook.clearFlyRoute();
    this.$options.flyRouteHook = null;
    this.$options.map3d && this.$options.map3d.destroy();
  },
};
</script>
<style scoped lang="scss">
.new-history-map-box{
  width: 100%;
  height: 100%;
  position: relative;
  #cesiumContainer_history {
    width: 100%;
    height: 100%;
  }
  .footer {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 10;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .play-controller {
      width: calc(100% - 100px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      .video-play {
        width: 20px;
        font-size: 20px;
        cursor: pointer;
      }
      .processing {
        cursor: pointer;
        margin-left: 10px;
        position: relative;
        width: calc(100% - 120px);
        position: relative;
        top: 1px;
        .processing-controller{
          cursor: pointer;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          background-color: #fff;
          width: 16px;
          height: 16px;
          border-radius: 8px;
          
        }
      }
    }
    .tool-controller {
      width: 100px;
      display: flex;
      justify-content: end;
      .full {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
