var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-history-map-box" },
    [
      _c("div", { attrs: { id: "cesiumContainer_history" } }),
      _c("div", { ref: "footer", staticClass: "footer" }, [
        _c("div", { staticClass: "play-controller" }, [
          _c("div", { style: "width:" + _vm.processingTextWidth + "px" }, [
            _vm._v(_vm._s(_vm.passedTime + "/" + _vm.totalTime)),
          ]),
          _c(
            "span",
            { staticClass: "video-play", on: { click: _vm.triggerPlay } },
            [
              _vm.loading
                ? _c("i", { staticClass: "el-icon-loading" })
                : _vm.play
                ? _c("i", { staticClass: "el-icon-video-pause" })
                : _c("i", { staticClass: "el-icon-video-play" }),
            ]
          ),
          _c(
            "div",
            {
              ref: "processing",
              staticClass: "processing",
              on: { click: _vm.setControllerPosition },
            },
            [
              _c("el-progress", {
                attrs: { percentage: _vm.percentage, "show-text": false },
              }),
              _c("div", {
                staticClass: "processing-controller",
                style: "left:" + _vm.percentage + "%",
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "tool-controller" }),
      ]),
      _c("BaseStationPop", {
        ref: "BaseStationPop",
        attrs: { id: "newhistory-route", detailData: _vm.baseStationDetail },
      }),
      _vm.hangarShow
        ? _c("HangarDetails", {
            ref: "hangarDetails",
            attrs: {
              show: _vm.hangarShow,
              baseData: _vm.hangarBaseData,
              detailsData: _vm.hangarDetailsData,
              videoData: _vm.hangarVideoData,
            },
            on: {
              "update:show": function ($event) {
                _vm.hangarShow = $event
              },
              closeDetails: _vm.closeHangarPanel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }