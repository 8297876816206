var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottom" }, [
    _c("div", { staticClass: "flex left" }, [
      _c("div", [
        _c("p", [_vm._v("EAS：")]),
        _c("span", [_vm._v(_vm._s(_vm.fix(_vm.store.uav_airespeed, 1)))]),
      ]),
      _c("div", [
        _c("p", [_vm._v("GS：")]),
        _c("span", [_vm._v(_vm._s(_vm.fix(_vm.store.uav_groundspeed, 1)))]),
      ]),
    ]),
    _c("div", { staticClass: "flex right" }, [
      _c("div", [
        _c("p", [_vm._v("AMSL：")]),
        _c("span", [_vm._v(_vm._s(_vm.fix(_vm.store.alt, 0)))]),
      ]),
      _c("div", [
        _c("p", [_vm._v("RFH：")]),
        _c("span", [
          _vm._v(_vm._s(_vm.fix(_vm.store.ranagefinder_distance, 1))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }