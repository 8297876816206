<template>
  <div class="dialBord">
    <i class="mark">
      {{ fix(angle, 2) }}
    </i>
    <ul id="dialBordBox">
      <template v-for="(item, index) in mark">
        <li
          v-if="item || item == 0"
          :key="index"
          :style="{ transform: makeScale(index) }"
        >
          <span v-if="index % 2 === 1">{{ item }}</span>
        </li>
      </template>
      <div class="fix">
        <Pitching :pitch="pitch" />
      </div>
    </ul>
  </div>
</template>

<script>
import Pitching from "./pitching.vue";
import { toPrecision } from "@/util/util";
export default {
  components: { Pitching },
  mounted() {
    this.mekeMark();
    // this.timer = setInterval(() => {
    //   const v = Math.ceil(Math.random() * 10);
    // }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  props: {
    roll: Number,
    pitch: Number,
  },
  watch: {
    roll: function (val) {
      if (val) {
        this.angle = val;
        this.makeScroll();
      }
    },
  },
  // computed: {
  //   roll() {
  //     return this.$store.state.screenLeftData.roll;
  //   },
  // },
  data() {
    return {
      // mark: [null, ,-50,-45,-40,-35,-30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, null],
      compare: 0,
      angle: 0,
      angleValue: 0,
      timer: null,
      mark: [],
    };
  },
  methods: {
    fix(val, precision) {
      if (!val) return "0";
      return toPrecision(val, precision);
    },
    makeScale(idx) {
      return `rotate(${(idx * 180) / 26}deg)`;
    },
    mekeMark() {
      for (let i = -60; i <= 60; i += 5) {
        this.mark.push(i);
      }
      this.mark = [null, ...this.mark, null];
    },
    makeScroll() {
      //  一个刻度180/14°
      const dialBordBox = document.querySelector("#dialBordBox");
      const difference = Number(this.angle - this.compare);
      this.angleValue = this.angleValue + difference;
      dialBordBox.style.transform = `rotate(${
        -this.angleValue * (6.8 / 5)
      }deg)`;
      this.compare = this.angle;
    },
  },
};
</script>

<style lang="scss" scoped>
$diameter: 400px;
$color: #4dfcff;
$r: 180px;
ul {
  margin: 0;
  padding: 0;
}
#dialBordBox {
  box-sizing: border-box;
}
.dialBord {
  transform: scale(0.8);
  list-style: none;
  height: 100%;
  width: 440px;
  position: relative;
  display: flex;
  // padding-top: 20px;
  .fix {
    position: absolute;
    left: 50%;
    top: 117px;
    transform: translate(-50%, 0);
  }
  .mark {
    display: block;
    padding: 0 10px;
    height: 24px;
    line-height: 26px;
    background: $color;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    // transform: scale(1);
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $color;
      position: absolute;
      left: 50%;
      top: -5px;
      transform: translate(-50%, 0);
    }
  }
  ul {
    width: $r * 2;
    height: $r;
    border: 3px solid $color;
    margin: 0px auto;
    margin-top: -20px;
    border-radius: $r $r 0 0;
    position: relative;
    border-bottom: none;
    transform-origin: bottom;
    li {
      color: white;
      width: 8px;
      height: 2px;
      background: $color;
      position: absolute;
      left: 1px;
      top: 172px;
      transform-origin: 176px;
      span {
        position: absolute;
        left: -40px;
        right: 0;
        transform: rotate(-90deg);
        bottom: -13px;
        font-size: 18px;
        text-align: center;
      }
    }
  }
}
</style>
