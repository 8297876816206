var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hudContainer" }, [
    _c(
      "div",
      { staticClass: "orientationWrap" },
      [_c("Orientation", { attrs: { heading: _vm.store.heading } })],
      1
    ),
    _c("div", { staticClass: "board" }, [
      _c(
        "div",
        { staticClass: "speedWrap" },
        [_c("Speed", { attrs: { uav_airespeed: _vm.store.uav_airespeed } })],
        1
      ),
      _c(
        "div",
        { staticClass: "dialBordWrap" },
        [
          _c("DialBord", {
            attrs: { roll: _vm.store.roll, pitch: _vm.store.pitch },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "elevationWrap" },
        [_c("Elevation", { attrs: { relative_alt: _vm.store.relative_alt } })],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      [_c("Bottom", { attrs: { store: _vm.store } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }