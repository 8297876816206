var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "plan-nodes" }, [
    _c("div", { staticClass: "title" }, [_vm._v("\n    任务航点\n  ")]),
    _c(
      "ul",
      { staticClass: "node-list" },
      _vm._l(_vm.nodes, function (node, index) {
        return _c(
          "li",
          {
            key: node.id,
            class: ["node-li", node.real ? "hasBackground" : ""],
          },
          [
            _c(
              "div",
              { class: ["node-number", node.real ? "hasIconBackground" : ""] },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.pad(index + 1, 3)) + "\n      "
                ),
              ]
            ),
            _c("img", {
              staticClass: "node-icon",
              attrs: { src: require("../../assets/img/map/" + node.icon) },
            }),
            _c("div", { staticClass: "node-label" }, [
              _vm._v("\n        " + _vm._s(node.label) + "\n      "),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }